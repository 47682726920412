import React from 'react'
import { Link } from 'gatsby'
import Button from '~components/global/button'
import Img from "gatsby-image"
import resolveLink from "~utils/resolveLink"

const ProductCard = ({ product }) => (
  <div className="product-card">
    <Link to={resolveLink(product)} className="product-card__image">
      <Img fluid={product?.content?.main?.basic?.productImages[0]?.asset?.fluid}/>
    </Link>
    <div className="product-card__text">
      <Link to={resolveLink(product)}><h3 className="m-caps">{product?.content?.main?.title}</h3></Link>
      <h6>Intensity {product?.content?.main?.basic?.strength}</h6>
      <Button to={resolveLink(product)} noMinWidth={true} >
        read more
      </Button>
    </div>
  </div>
)

export default ProductCard
