import React, { useRef, useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { window } from "browser-monads"
import useBreakpoint from "~utils/useBreakpoint"
import useSiteSettings from "~utils/useSiteSettings"
import moment from 'moment'
import BlockContent from '@sanity/block-content-to-react'
import { SanityImage } from "~components/sanityImage"
import resolveLink from '~utils/resolveLink'

import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

import Layout from "~components/layout"
import Section from "~components/global/section"
import Slider from "~components/global/slider"
import Newsletter from "~components/global/newsletter"
import ArticleCard from "~components/global/articleCard"
import Button from "~components/global/button"
import ProductCard from "~components/global/productCard"
import { Facebook, Twitter, Pinterest } from "~components/global/svg"
import SEO from "~components/seo"
import VideoModule from "../components/modules/videoModule"

const Article = ({ data }) => {
  const signUpRef = useRef()
  const [pageReady, setPageReady] = useState(false)
  const { mobile } = useBreakpoint()
  const { newsletterSignUpText } = useSiteSettings()

  useEffect(() => {
    setPageReady(true)
  }, [])

  const a = data.sanityArticle

  const ConditionalLink = ({ condition, linkWrapper, children }) => 
  condition ? linkWrapper(children) : children;

  const serializers = {
    types: {
      basicImage: ({ node }) => (
        <figure>
          <ConditionalLink
            condition={node.link}
            linkWrapper={children => (
              node.link?.internal ? 
              <Link to={resolveLink(node.link?.internal)}>{children}</Link>
              :
              <a href={node.link?.external} target="_blank">{children}</a>
            )} 
          >
            {node.asset?._id.includes('gif') ?
              <img src={node.asset?.url}/> 
              : 
              <SanityImage imageId={node?.asset} alt={node.alt}/>
            }
          </ConditionalLink>
          {node.caption &&
            <figcaption>{node.caption}</figcaption>
          }
        </figure>
      ),
      internalLink: ({ node }) => (
        <div>
          <Button to={resolveLink(node.to)} color="orange">{node.linkText}</Button>
        </div>
      ),
      externalLink: ({ node }) => ( 
        <div> 
          <Button href={node.url} color="orange">{node.linkText}</Button>
        </div>
      ),
      videoEmbed: ({ node }) => ( 
        <div> 
          <VideoModule
            video={node.url} 
            fullWidth
          />
        </div>
      )
    }
  }

  return(
  <Layout>
    <SEO metaInfo={a._rawMeta} pagePath={resolveLink(a)}/>
    <Section name="article-header ">
      <div className="article-header__image">
      {a.headerImage?.asset?._id?.includes('gif') ? 
        <img src={a.headerImage?.asset?.url} style={{display: 'block'}}/> : 
        <Img fluid={a.headerImage?.asset?.fluid}/>
      }
      </div>
      <div className="article-header__text">
        <div>
          {a.publishedAt &&<h5 className="s-caps">{moment(a.publishedAt).format('DD.MM.YY')}</h5>}
          <h1 className="l-sans">{a.title}</h1>
          {a._rawExcerpt &&
            <BlockContent blocks={a._rawExcerpt} />
          }
        </div>
      </div>
    </Section>
    <Section name="article">
      <div className="article__body">
        <BlockContent blocks={a._rawBody} serializers={serializers} />
      </div>
      <div className="article__sidebar" style={{marginBottom: pageReady && !mobile ? signUpRef.current.clientHeight + 40 + 'px' : '30px'}}>
        <h6 className="s-caps">Share</h6>
        <div className="social-icons">
        <FacebookShareButton url={window.location.href}>
          <div className="social-button">
            <Facebook />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href}>
          <div className="social-button">
            <Twitter />
          </div>
        </TwitterShareButton>
        <PinterestShareButton url={window.location.href} media="https://cdn.sanity.io/images/j9foxki4/production/92ea4af078daf86115e8095eb09ea0a0fefa9403-1080x1080.jpg">
          <div className="social-button">
            <Pinterest />
          </div>
        </PinterestShareButton>
        </div>
        {data.sanityArticle?.relatedProducts?.length > 0 && 
          <div className="related-products">
            <h4 className="s-caps">Related Products</h4>
            { mobile ? 
              <Slider slidesVisible={1} arrows={"sans"} swipeIndicator={true}>
                {data.sanityArticle?.relatedProducts?.map(product => (
                  <ProductCard product={product}/>
                ))}
              </Slider>
              :
              data.sanityArticle?.relatedProducts?.map(product => (
                <ProductCard product={product}/>
              ))
            }
          </div>
        }
      </div>
      <div className="article__sign-up" ref={signUpRef}>
        <p>
          {newsletterSignUpText}
        </p>
        <Newsletter successMessage="Done!" buttonText={<span>&rarr;</span>}/>
      </div>
    </Section>
    <Section name="related">
      <h4 className="l-sans">Related Articles</h4>  
      {a.related.map(ra => (
        <ArticleCard
          date={ra.publishedAt && moment(ra.publishedAt).format('DD.MM.YY')}
          image={<Img fluid={{...ra.headerImage?.asset?.fluid, aspectRatio: 1.5}}/>}
          title={ra.title}
          intro={<BlockContent blocks={ra._rawExcerpt} />}
          url={resolveLink(ra)}
          key={ra._id}
        >
        </ArticleCard>
      ))}
    </Section>
  </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityArticle(id: {eq: $id}) {
      _rawMeta(resolveReferences: {maxDepth: 2})
      title
      publishedAt
      _type
      slug {
        current
      }
      headerImage {
        asset {
          _id
          url
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      _rawBody(resolveReferences: {maxDepth: 10})
      _rawExcerpt
      _key
      related {
        headerImage {
          asset {
            _id
            url
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        } 
        publishedAt
        slug {
          current
        }
        title
        _key
        _id
        _type
        _rawExcerpt
      }
      relatedProducts {
        _type
        _id
        content {
          main {
            title
            slug {
              current
            }
            defaultGroup {
              slug {
                current
              }
            }
            basic {
              strength
              productImages {
                asset {
                  fluid {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Article
